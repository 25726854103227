import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "./Layout"
import { AdvancedImage } from "@cloudinary/react"
import { Cloudinary } from "@cloudinary/url-gen"
import { lazyload, placeholder } from "@cloudinary/react"
import { fill, scale } from "@cloudinary/url-gen/actions/resize"
import * as style from "./post-template.module.css"
import { seoHead, isSafariLess16 } from "../components/utils.js"
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share"
import { useStickyBox } from "react-sticky-box"

export const query = graphql`
  query ($id: ID!, $databaseId: [ID]) {
    wpgraphql {
      post(id: $id) {
        id
        title
        content
        date
        uri
        categories {
          nodes {
            databaseId
            slug
            name
          }
        }
        insights {
          contentType
          hideFromListings
        }
        featuredImage {
          node {
            mediaDetails {
              file
            }
            mimeType
            altText
          }
        }

        cfInsightsV2 {
          articleCta
          ctaImage {
            altText
            mediaDetails {
              file
            }
          }
          ctaIntro
          ctaLabel
          ctaSubtext
          ctaLink {
            url
            title
          }
          subTitleIntro
          mcSections {
            copyArea
            quote
            quoteAuthor {
              ... on WPGraphQL_Postauthor {
                id
                cfAuthors {
                  jobTitle
                }
                title
                featuredImage {
                  node {
                    altText
                    mediaDetails {
                      file
                    }
                  }
                }
              }
            }
            sectionType
            sec123Intro
            sec123Title
            sec123Items {
              sec123ItemContent
              sec123ItemTitle
            }
            videoCaption
            videoId
            sectionImage {
              altText
              mediaDetails {
                file
              }
            }
            imageCaption
          }
          selectTemplate
          midSectionQuote
          twitterHashtags {
            aHastag
          }

          keyTakeaways {
            aTakeaway
          }
          hideDiversityBlock
          articleCta
          ctaIntro
          ctaLabel
          ctaLink {
            url
          }
          footerInclude
          postAuthors {
            anAuthor {
              ... on WPGraphQL_Postauthor {
                id
                title
                cfAuthors {
                  jobTitle
                }
                featuredImage {
                  node {
                    altText
                    mediaDetails {
                      file
                    }
                  }
                }
              }
            }
          }
        }

        seo {
          title
          metaDesc
          opengraphTitle
          opengraphDescription
          opengraphUrl
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphImage {
            mimeType
            mediaDetails {
              file
            }
          }
        }
      }

      posts(first: 6, where: { categoryIn: $databaseId }) {
        edges {
          node {
            uri
            title
            id
            date
            insights {
              contentType
              hideFromListings
            }
            featuredImage {
              node {
                altText
                mimeType
                mediaDetails {
                  file
                }
                slug
              }
            }
          }
        }
      }

      themeGeneralSettings {
        globalDiversity {
          globalDivMainContent
          globalDivTitle
        }
      }
    }
  }
`

const PostTemplate = ({ data }) => {
  //  set up post data
  const post = data.wpgraphql.post
  const relatedPosts = data.wpgraphql.posts.edges
  const isV2Template = post.cfInsightsV2.selectTemplate
  const stickyRef = useStickyBox({ offsetTop: 20, offsetBottom: 20 })

  // Global diversity block

  const divTitle =
    data.wpgraphql.themeGeneralSettings.globalDiversity.globalDivTitle
  const divMainCopy =
    data.wpgraphql.themeGeneralSettings.globalDiversity.globalDivMainContent

  // article type
  let iconUse = "url(/assets/img/icon_article.svg)"
  let iconUseV1 = "url(/assets/img/icon_article_white.svg)"
  let contentType = "Article"

  switch (post.insights.contentType) {
    case "Podcast":
      iconUse = "url(/assets/img/icon_podcast.svg)"
      iconUseV1 = "url(/assets/img/icon_podcast_white.svg)"
      contentType = "Podcast"
      break
    case "Video":
      iconUse = "url(/assets/img/icon_video.svg)"
      iconUseV1 = "url(/assets/img/icon_video_white.svg)"
      contentType = "Video"
      break
    default:
      iconUse = "url(/assets/img/icon_article.svg)"
      iconUseV1 = "url(/assets/img/icon_article_white.svg)"
      contentType = "Article"
  }

  // featured image
  const cld = new Cloudinary({
    cloud: {
      cloudName: process.env.GATSBY_CLOUDINARY_CLOUD,
    },
    url: {
      analytics: false,
    },
  })

  let altText
  let imgUri

  if (post.featuredImage) {
    altText = post.featuredImage.node.altText
    imgUri = post.featuredImage.node.mediaDetails.file
  } else {
    altText = "insights featured image"
    imgUri = "industries-fintech_u8zzb1.webp"
  }

  const theImage = cld.image(`${process.env.GATSBY_API_FOLDER_URL}${imgUri}`)

  theImage.resize(scale().width(950))
  theImage.format("auto")

  // date format
  const d = new Date(post.date)
  const theDate = new Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    month: "long",
    day: "2-digit",
  }).format(d)

  // category list
  const postCats = post.categories.nodes.map(categoryItem => (
    <span key={categoryItem.databaseId}>
      &bull;{" "}
      <Link to={`/insights/${categoryItem.slug}/`} className={style.cat_list}>
        {categoryItem.name}{" "}
      </Link>
    </span>
  ))

  const relatedContent = relatedPosts.map((relatedPost, index) => {
    const altText = relatedPost.node.featuredImage.node.altText
    const imgUri = relatedPost.node.featuredImage.node.mediaDetails.file
    const theImage = cld.image(`${process.env.GATSBY_API_FOLDER_URL}${imgUri}`)

    theImage.resize(fill().width(735).height(420))
    theImage.quality("70")
    theImage.format("auto")

    // article type
    let iconUseRelated = "url(/assets/img/icon_article_white.svg)"
    let contentTypeRelated = "Article"

    switch (relatedPost.node.insights.contentType) {
      case "Podcast":
        iconUseRelated = "url(/assets/img/icon_podcast_white.svg)"
        contentTypeRelated = "Podcast"
        break
      case "Video":
        iconUseRelated = "url(/assets/img/icon_video_white.svg)"
        contentTypeRelated = "Video"
        break
      default:
        iconUseRelated = "url(/assets/img/icon_article_white.svg)"
        contentTypeRelated = "Article"
    }

    if (!relatedPost.node.insights.hideFromListings) {
      return (
        <div key={index} className="col-12 col-lg-8 row p-0 mb-5">
          <div className="col-12 col-lg-6 mb-3 mb-lg-0">
            <Link to={relatedPost.node.uri}>
              <AdvancedImage
                cldImg={theImage}
                plugins={[
                  lazyload({
                    rootMargin: "200px",
                    threshold: 0.25,
                  }),
                  placeholder({ mode: "blur" }),
                ]}
                alt={altText}
                className={`w-100`}
              />
            </Link>
          </div>
          <div className="col-12 col-lg-6 d-flex flex-column justify-content-center">
            <h5
              className={`mb-3 ${style.podcast_icon}`}
              style={{ backgroundImage: iconUseRelated }}
            >
              {contentTypeRelated} /{postCats}
            </h5>
            <Link to={relatedPost.node.uri}>
              <h3 className="h4 mb-3">{relatedPost.node.title}</h3>
            </Link>
          </div>
        </div>
      )
    } else {
      return null
    }
  })

  // sidebar related content

  const relatedContentSideBar = relatedPosts.map((relatedPost, index) => {
    const altText = relatedPost.node.featuredImage.node.altText
    const imgUri = relatedPost.node.featuredImage.node.mediaDetails.file
    const theImage = cld.image(`${process.env.GATSBY_API_FOLDER_URL}${imgUri}`)

    theImage.resize(fill().width(735).height(420))
    theImage.quality("70")
    theImage.format("auto")

    const theArtID = post.id
    const theRelatedId = relatedPost.node.id

    //console.log(theArtID);

    // article type
    let iconUseRelated = "url(/assets/img/icon_article_black.svg)"
    let contentTypeRelated = "Article"

    switch (relatedPost.node.insights.contentType) {
      case "Podcast":
        iconUseRelated = "url(/assets/img/icon_podcast_white.svg)"
        contentTypeRelated = "Podcast"
        break
      case "Video":
        iconUseRelated = "url(/assets/img/icon_video_white.svg)"
        contentTypeRelated = "Video"
        break
      default:
        iconUseRelated = "url(/assets/img/icon_article.svg)"
        contentTypeRelated = "Article"
    }

    const pDate = new Date(relatedPost.node.date)

    if (
      !relatedPost.node.insights.hideFromListings &&
      theArtID !== theRelatedId
    ) {
      const thePDate = new Intl.DateTimeFormat("en-GB", {
        year: "numeric",
        month: "long",
        day: "2-digit",
      }).format(pDate)
      return (
        <div key={index} className="row p-0 mb-2">
          <div className="col-12 mb-3 mb-lg-0">
            <Link to={relatedPost.node.uri}>
              <AdvancedImage
                cldImg={theImage}
                plugins={[
                  lazyload({
                    rootMargin: "200px",
                    threshold: 0.25,
                  }),
                  placeholder({ mode: "blur" }),
                ]}
                alt={altText}
                className={`w-100`}
              />
            </Link>
            <div className="pt-3">
              <h5
                className={`mb-2
                              ${style.podcast_icon}`}
                style={{ backgroundImage: iconUseRelated }}
              >
                {contentTypeRelated} &bull; {thePDate}
              </h5>
              <Link to={relatedPost.node.uri}>
                <h3 className="h4 mb-3">{relatedPost.node.title}</h3>
              </Link>
            </div>
          </div>
        </div>
      )
    } else {
      return null
    }
  })

  // Content component to replace youtube video shortcodes with actual video embeds.

  var TheContentBlock = ({ thecontent }) => {
    var theContentFIltered = thecontent

    // Seperate out the content into array based on shortcodes.
    var s = theContentFIltered
    var arrStr = s.split(/[[\]]/)

    //loop array to find youtube & vimeo codes
    //console.log(arrStr);

    for (var i = 0; i < arrStr.length; i++) {
      if (arrStr[i].includes("youtubevideo")) {
        var arrStr_2 = arrStr[i].split(/&#8\d\d\d;/)
        arrStr[i] = `<div class="video-embed-caption-container">
                      <div class='video-embed-container'>
                        <iframe src='https://www.youtube-nocookie.com/embed/${arrStr_2[1]}?rel=0'
                        frameborder="0"
                        allowfullscreen>
                        </iframe>
                      </div>
                      <span class="p-2 d-inline-block">${arrStr_2[3]}</span>
                    </div>`
      }

      if (arrStr[i].includes("vimeovideo")) {
        var arrStr_3 = arrStr[1].split(/&#8\d\d\d;/)
        arrStr[i] = `<div class="video-embed-container">
                      <iframe src="https://player.vimeo.com/video/${arrStr_3[1]}"
                        frameborder="0
                        allow="autoplay; fullscreen"
                        allowfullscreen>
                      </iframe>
                    </div>`
      }
    }

    var theUpdatedContent = arrStr.join("")

    // replace any cms based links in the content ... content.waracle.com

    theUpdatedContent = theUpdatedContent.replace(
      /https:\/\/content.waracle.com/g,
      ""
    )

    return <div dangerouslySetInnerHTML={{ __html: theUpdatedContent }} />
  }

  // key takeaways

  var keyInsights = null
  const showInsights = post.cfInsightsV2.keyTakeaways

  if (isV2Template === "version2" && showInsights != null) {
    const noInsights = showInsights.length
    var insightsX = 0

    keyInsights = showInsights.map((keyInsight, index) => {
      insightsX++

      return (
        <div key={index}>
          <div
            style={{
              fontSize: "22px ",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div className={style.key_takeaways}>
              <img
                src="/assets/img/white_right_arrow.svg"
                alt="takeaway arrow icon"
              />
            </div>
            <div>{keyInsight.aTakeaway}</div>
          </div>
          {insightsX < noInsights && <hr className="mt-3 mb-3" />}
        </div>
      )
    })
  }

  // Authors

  var authors = null
  const testingAuth = post.cfInsightsV2.postAuthors

  if (isV2Template === "version2" && testingAuth != null) {
    authors = testingAuth.map((author, index) => {
      const altText = author.anAuthor.featuredImage.node.altText
      var imgUri = author.anAuthor.featuredImage.node.mediaDetails.file

      const theImage = cld.image(
        `${process.env.GATSBY_API_FOLDER_URL}${imgUri}`
      )

      theImage.resize(fill().width(200).height(200))
      theImage.quality("70")
      theImage.format("auto")

      return (
        <div key={index} className="d-flex align-items-center mb-4">
          <div
            className={`${style.authors} d-flex align-items-center justify-content-center`}
          >
            <AdvancedImage cldImg={theImage} alt={altText} />
          </div>
          <div>
            {author.anAuthor.title}
            <br />
            <span>{author.anAuthor.cfAuthors.jobTitle}</span>
          </div>
        </div>
      )
    })
  }

  const isSafariV16 = isSafariLess16()

  // Share hashtags

  var hashTags = []
  //var getHashTags = null;
  const hTagsBase = post.cfInsightsV2.twitterHashtags || []

  hTagsBase.map(aHashTag => {
    hashTags.push(aHashTag.aHastag)
    return null
  })

  // new content blocks

  var conRepeater = data.wpgraphql.post.cfInsightsV2.mcSections
  var mainContent = null

  if (isV2Template === "version2") {
    mainContent = conRepeater.map((conPiece, index) => {
      if (conPiece.sectionType === "list123") {
        const listItems = conPiece.sec123Items.map((aListItem, index) => {
          let theNumber = index + 1

          return (
            <div className="row mt-5" key={index}>
              <div className="col-2 col-md-1">
                <img
                  src={`/assets/img/orange_${theNumber}.svg`}
                  alt={`list number icon ${theNumber}`}
                  style={{
                    maxWidth: "50px",
                    maxHeight: "60px",
                  }}
                />
              </div>
              <div className="col-10 col-md-11">
                <h3 className="mb-4">{aListItem.sec123ItemTitle}</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: aListItem.sec123ItemContent,
                  }}
                />
              </div>
            </div>
          )
        })

        return (
          <div key={index}>
            <hr
              className={`${style.margin_y_5_rem}`}
              style={{
                maxWidth: "180px",
                marginTop: "3.5rem",
                marginBottom: "3.5rem",
              }}
            />
            <h2 className="mb-5">{conPiece.sec123Title}</h2>
            <p
              className="pb-5"
              style={{ fontSize: "18px", lineHeight: "34px" }}
            >
              {conPiece.sec123Intro}
            </p>
            {listItems}
            <hr
              className={`${style.margin_y_5_rem}`}
              style={{
                maxWidth: "180px",
                marginTop: "2.5rem",
                marginBottom: "4.5rem",
              }}
            />
          </div>
        )
      }

      if (conPiece.sectionType === "image") {
        let embAltText
        let embImgUri

        if (conPiece.sectionImage !== null) {
          embAltText = conPiece.sectionImage.altText
          embImgUri = conPiece.sectionImage.mediaDetails.file

          const embImage = cld.image(
            `${process.env.GATSBY_API_FOLDER_URL}${embImgUri}`
          )

          embImage.resize(scale().width(950))
          embImage.format("auto")

          return (
            <div className="mb-5" key={index}>
              <AdvancedImage
                cldImg={embImage}
                alt={embAltText}
                className="mw-100"
              />
              {conPiece.imageCaption !== null && (
                <span
                  className="p-2 d-block"
                  style={{ backgroundColor: "#F5F7F9" }}
                >
                  {conPiece.imageCaption}
                </span>
              )}
            </div>
          )
        } else {
          return <></>
        }
      }
      if (conPiece.sectionType === "video") {
        return (
          <div className="video-embed-caption-container mb-5" key={index}>
            <div className="video-embed-container">
              <iframe
                src={`https://www.youtube.com/embed/${conPiece.videoId}?rel=0`}
                frameBorder="0"
                allowFullScreen
                title={conPiece.videoCaption}
              ></iframe>
            </div>
            <span className="p-2 d-inline-block">{conPiece.videoCaption}</span>
          </div>
        )
      }
      if (conPiece.sectionType === "quote") {
        const altTextAuth = conPiece.quoteAuthor.featuredImage.node.altText
        var imgUriAuth =
          conPiece.quoteAuthor.featuredImage.node.mediaDetails.file
        // const theImageAuth = cld.image(`${process.env.GATSBY_API_FOLDER_URL}${imgUriAuth}`);

        const theImageAuth = cld.image(
          `${process.env.GATSBY_API_FOLDER_URL}${imgUriAuth}`
        )

        theImageAuth.resize(fill().width(200).height(200))
        theImageAuth.format("auto")

        return (
          <div key={index}>
            <div className={`mb-0 ${style.quotation_inner}`}>
              <img
                src="/assets/img/quote_orange_2.svg"
                alt="quote icon"
                className={`d-none d-md-inline ${style.quotation_img}`}
              />
              <img
                src="/assets/img/quote_orange_1.svg"
                alt="quote icon"
                className={style.quotation_img_2}
              />
            </div>
            <div className={`mt-5 mb-5 ${style.quotation_container}`}>
              <div className={`px-3 px-md-5`}>
                <h3 className="pe-md-5">{conPiece.quote}</h3>

                <div
                  className="d-lg-flex mt-4 mt-lg-0"
                  style={{ justifyContent: "space-between" }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <AdvancedImage
                      cldImg={theImageAuth}
                      alt={altTextAuth}
                      style={{
                        width: "75px",
                        borderRadius: "90%",
                        marginRight: "15px",
                      }}
                    />
                    <p className="mt-0 mb-0" style={{ lineHeight: "24px" }}>
                      {conPiece.quoteAuthor.title}
                      <br />
                      <span style={{ fontSize: ".9rem" }}>
                        {conPiece.quoteAuthor.cfAuthors.jobTitle}
                      </span>
                    </p>
                  </div>

                  <div
                    style={{
                      marginTop: "1.75rem",
                      marginBottom: "2.0rem",
                      borderRadius: "50px",
                      backgroundColor: "white",
                      display: "inline-block",
                      padding: "1rem",
                    }}
                  >
                    <span className="d-none d-sm-inline me-3">Share quote</span>
                    <FacebookShareButton
                      url={`https://waracle.com/${post.uri}`}
                      quote={conPiece.quote}
                    >
                      <FacebookIcon
                        size={32}
                        round={true}
                        bgStyle={{ fill: "white" }}
                        iconFillColor={"black"}
                      />
                    </FacebookShareButton>
                    <TwitterShareButton
                      url={`https://waracle.com/${post.uri}`}
                      title={conPiece.quote}
                      hashtags={hashTags}
                      className="ms-2"
                    >
                      <TwitterIcon
                        size={32}
                        round={true}
                        bgStyle={{ fill: "white" }}
                        iconFillColor={"black"}
                      />
                    </TwitterShareButton>
                    <LinkedinShareButton
                      url={`https://waracle.com/${post.uri}`}
                      title={conPiece.quote}
                      summary={conPiece.quote}
                      source="website"
                      className="ms-2"
                    >
                      <LinkedinIcon
                        size={32}
                        round={true}
                        bgStyle={{ fill: "white" }}
                        iconFillColor={"black"}
                      />
                    </LinkedinShareButton>
                    <WhatsappShareButton
                      url={`https://waracle.com/${post.uri}`}
                      title={conPiece.quote}
                      className="ms-2"
                    >
                      <WhatsappIcon
                        size={32}
                        round={true}
                        bgStyle={{ fill: "white" }}
                        iconFillColor={"black"}
                      />
                    </WhatsappShareButton>
                    <EmailShareButton
                      url={`https://waracle.com/${post.uri}`}
                      subject={`Waracle Insights: ${post.title}`}
                      body={conPiece.quote}
                      separator={` : `}
                      className="ms-2"
                    >
                      <EmailIcon
                        size={32}
                        round={true}
                        bgStyle={{ fill: "white" }}
                        iconFillColor={"black"}
                      />
                    </EmailShareButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
      if (conPiece.sectionType === "copy") {
        // replace cms based URLs
        let updatedCopy = conPiece.copyArea.replace(
          /https:\/\/content.waracle.com/g,
          ""
        )
        // remove <span></span> generated in google docs and pasted into cms
        updatedCopy = updatedCopy.replace(/(<sp([^>]+)>)/gi, "")
        updatedCopy = updatedCopy.replace(/(<\/span>)/gi, "")
        return (
          <div dangerouslySetInnerHTML={{ __html: updatedCopy }} key={index} />
        )
      }
      return null
    })
  }

  // CTA

  if (isV2Template === "version2" && post.cfInsightsV2.articleCta !== null) {
    var ctaAltText
    var ctaImgUri

    if (post.cfInsightsV2.ctaImage !== null) {
      ctaAltText = post.cfInsightsV2.ctaImage.altText
      ctaImgUri = post.cfInsightsV2.ctaImage.mediaDetails.file

      var ctaImage = cld.image(
        `${process.env.GATSBY_API_FOLDER_URL}${ctaImgUri}`
      )

      ctaImage.resize(scale().width(950))
      ctaImage.format("auto")
    }
  }

  // return v2 design if true
  if (isV2Template === "version2") {
    return (
      <>
        <Layout mode="mode-light">
          <div
            className="container mt-5 pt-5"
            style={{ position: "relative", zIndex: "0" }}
          >
            <div className="row py-5 align-items-xl-start">
              <div className="col-12 col-lg-9">
                <div className="pe-lg-5">
                  <div id="hide-row-when-nav-open">
                    <div
                      className="mb-5"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <div
                        style={{
                          backgroundImage: iconUse,
                          width: "40px",
                          height: "40px",
                          backgroundSize: "20px",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          backgroundColor: "#f5f7f9",
                          borderRadius: "90%",
                          marginRight: "15px",
                        }}
                      ></div>
                      <h5 className={`header-fade-in mb-0`}>
                        {contentType} {postCats}
                        <br />
                        {theDate}
                      </h5>
                    </div>
                    <div className="animated-headline-wrap mb-5">
                      <h1 className="lower new_style">
                        <span
                          className={`new_style_addit_light ${
                            isSafariV16 ? "safari_16" : ""
                          }`}
                          dangerouslySetInnerHTML={{ __html: post.title }}
                        />
                      </h1>
                      <p className="mt-5" style={{ fontSize: "20px" }}>
                        {post.cfInsightsV2.subTitleIntro}
                      </p>
                    </div>
                  </div>
                  <AdvancedImage
                    cldImg={theImage}
                    alt={altText}
                    className="w-100 mb-5 mt-3"
                  />

                  {/* Diversity block - visible by default, can be removed per article */}
                  {!post.cfInsightsV2.hideDiversityBlock && (
                    <div
                      className="d-md-flex"
                      style={{
                        background: "black",
                        padding: "2rem",
                        borderRadius: "7px",
                        marginBottom: "3rem",
                      }}
                    >
                      <div className="d-flex flex-md-column justify-content-md-center">
                        <div className="mb-md-4" style={{ display: "flex" }}>
                          <img
                            src="/assets/img/Teamwork-1.svg"
                            alt="teamwork icon"
                            style={{
                              width: "50px",
                              borderRadius: "50px",
                              marginRight: "1.5rem",
                            }}
                          />
                          <img
                            src="/assets/img/Teamwork-3.svg"
                            alt="teamwork icon"
                            style={{
                              width: "50px",
                              borderRadius: "50px",
                              marginRight: "1.5rem",
                            }}
                          />
                        </div>
                        <div style={{ display: "flex" }}>
                          <img
                            src="/assets/img/Teamwork-4.svg"
                            alt="teamwork icon"
                            style={{
                              width: "50px",
                              borderRadius: "50px",
                              marginRight: "1.5rem",
                            }}
                          />
                          <img
                            src="/assets/img/Teamwork-2.svg"
                            alt="teamwork icon"
                            style={{
                              width: "50px",
                              borderRadius: "50px",
                              marginRight: "1.5rem",
                            }}
                          />
                        </div>
                      </div>
                      <div>
                        <h3 className="text-white mt-4 mt-md-0">{divTitle}</h3>
                        <p className="text-white mb-0">{divMainCopy}</p>
                      </div>
                    </div>
                  )}

                  {/* Include key insights if required */}

                  {showInsights != null && (
                    <>
                      <h2 className="pt-4 mb-5">Key Insights</h2>
                      <div className="pb-5 mb-3">{keyInsights}</div>
                    </>
                  )}

                  <div className={`${style.post_body} mb-2`}>{mainContent}</div>

                  {/* Include CTA if required */}

                  {post.cfInsightsV2.articleCta && (
                    <div className="mt-5 pt-5 mb-5 pb-5">
                      <div
                        className="pb-5"
                        style={{
                          backgroundColor: "#F5F7F9",
                        }}
                      >
                        <>
                          {post.cfInsightsV2.ctaImage !== null && (
                            <AdvancedImage
                              cldImg={ctaImage}
                              alt={ctaAltText}
                              className="mw-100"
                            />
                          )}
                        </>
                        <div className="text-center">
                          <h4
                            className="pt-5"
                            style={{
                              textTransform: "unset",
                              fontSize: "28px",
                              lineHeight: "36px",
                            }}
                            dangerouslySetInnerHTML={{
                              __html: post.cfInsightsV2.ctaIntro,
                            }}
                          />
                          <p className="mb-3">{post.cfInsightsV2.ctaSubtext}</p>
                          <Link
                            to={post.cfInsightsV2.ctaLink.url}
                            className={`${style.cta_link} mt-4`}
                            style={{ minWidth: "150px" }}
                          >
                            {post.cfInsightsV2.ctaLabel}
                          </Link>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div
                className={`col-12 col-lg-3 ${style.aside_big_bg}`}
                ref={stickyRef}
              >
                <div className="mb-5">
                  <span
                    style={{
                      display: "block",
                      marginBottom: "15px",
                    }}
                  >
                    Share this article
                  </span>
                  <FacebookShareButton
                    url={`https://waracle.com/${post.uri}`}
                    quote={post.cfInsightsV2.midSectionQuote}
                  >
                    <FacebookIcon
                      size={32}
                      round={true}
                      bgStyle={{
                        fill: "white",
                      }}
                      iconFillColor={"black"}
                    />
                  </FacebookShareButton>
                  <TwitterShareButton
                    url={`https://waracle.com/${post.uri}`}
                    title={post.cfInsightsV2.midSectionQuote}
                    hashtags={hashTags}
                    className="ms-2"
                  >
                    <TwitterIcon
                      size={32}
                      round={true}
                      bgStyle={{ fill: "white" }}
                      iconFillColor={"black"}
                    />
                  </TwitterShareButton>
                  <LinkedinShareButton
                    url={`https://waracle.com/${post.uri}`}
                    title={post.cfInsightsV2.midSectionQuote}
                    summary={post.cfInsightsV2.midSectionQuote}
                    source="website"
                    className="ms-2"
                  >
                    <LinkedinIcon
                      size={32}
                      round={true}
                      bgStyle={{ fill: "white" }}
                      iconFillColor={"black"}
                    />
                  </LinkedinShareButton>
                  <WhatsappShareButton
                    url={`https://waracle.com/${post.uri}`}
                    title={post.cfInsightsV2.midSectionQuote}
                    className="ms-2"
                  >
                    <WhatsappIcon
                      size={32}
                      round={true}
                      bgStyle={{ fill: "white" }}
                      iconFillColor={"black"}
                    />
                  </WhatsappShareButton>
                  <EmailShareButton
                    url={`https://waracle.com/${post.uri}`}
                    subject={`Waracle Insights: ${post.title}`}
                    body={post.cfInsightsV2.midSectionQuote}
                    separator={` : `}
                    className="ms-2"
                  >
                    <EmailIcon
                      size={32}
                      round={true}
                      bgStyle={{ fill: "white" }}
                      iconFillColor={"black"}
                    />
                  </EmailShareButton>
                </div>
                {testingAuth != null && (
                  <h2 className="mb-3 mb-lg-5">Authors</h2>
                )}
                {authors}
                <h2 className="mb-3 mb-lg-5 pt-4">Related</h2>
                {relatedContentSideBar}
              </div>
            </div>
          </div>

          {/* Include footer if required */}
          {post.cfInsightsV2.footerInclude && (
            <div className="container mb-5 pb-5" style={{ marginTop: "-3rem" }}>
              <div className="row">
                <div className="col-12">
                  <div className="d-flex justify-content-center">
                    <hr
                      className={`${style.margin_y_5_rem}`}
                      style={{ width: "120px" }}
                    />
                  </div>
                  <span
                    className="text-center d-block mb-5"
                    style={{ textTransform: "unset", fontSize: "36px" }}
                  >
                    End of Insight.
                  </span>
                  <div className="text-center">
                    <img
                      src="/assets/img/waracle-logo-black-v2.svg"
                      alt="logo"
                      style={{ maxWidth: "120px" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </Layout>
      </>
    )
    // else return default design
  } else {
    return (
      <>
        <Layout>
          <div className="darkgrey_bg pt-6">
            <div className="container" id="hide-row-when-nav-open">
              <div className="row justify-content-center">
                <div className="col-12 col-lg-8 color-white animated-headline">
                  <h5
                    className={`mb-3 mt-5 header-fade-in ${style.podcast_icon}`}
                    style={{ backgroundImage: iconUseV1 }}
                  >
                    {contentType} {postCats}
                  </h5>
                  <div className="animated-headline-wrap mb-4">
                    <h1 className="lower new_style">
                      <span
                        className={`new_style_addit ${
                          isSafariV16 ? "safari_16" : ""
                        }`}
                        dangerouslySetInnerHTML={{ __html: post.title }}
                      />
                    </h1>
                  </div>
                  <div className="row align-items-end mb-4 header-fade-in">
                    <div className="col-12 col-sm-6 d-flex mt-4">
                      <span>
                        <h5>Categories: {postCats}</h5>
                      </span>
                    </div>
                    <div className="col-12 col-sm-6 text-start text-sm-end mt-4">
                      <h5>Posted {theDate}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="black_white_bg mb-5">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 col-lg-8">
                  <AdvancedImage
                    cldImg={theImage}
                    alt={altText}
                    className="w-100"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={`container mb-100 single_post ${style.single_post}`}>
            <div className={`${style.row} row justify-content-center`}>
              <div className={`${style.post_body} col-12 col-lg-8`}>
                <TheContentBlock thecontent={post.content} />
              </div>
            </div>
          </div>

          <div className="darkgrey_bg color-white pb-6 pt-6">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 col-lg-8  mb-3 mb-lg-5">
                  <h2>Related insights</h2>
                </div>
                {relatedContent}
              </div>
            </div>
          </div>
        </Layout>
      </>
    )
  }
}

export default PostTemplate

// Metadata in head from gatsby ^v4.19.0
// replaces helmet which wasn't generating the tags statically

export const Head = ({ data }) => {
  const post = data.wpgraphql.post
  const seoTags = seoHead(post.seo, post.uri)
  return seoTags
}
