// extracted by mini-css-extract-plugin
export var article_icon = "post-template-module--article_icon--9a76a";
export var aside_big_bg = "post-template-module--aside_big_bg--24f84";
export var authors = "post-template-module--authors--4af92";
export var cat_list = "post-template-module--cat_list--080ca";
export var cta_link = "post-template-module--cta_link--7d97a";
export var key_takeaways = "post-template-module--key_takeaways--4d592";
export var lightbulb_icon = "post-template-module--lightbulb_icon--d9014";
export var linkedin_icon = "post-template-module--linkedin_icon--4128b";
export var margin_y_5_rem = "post-template-module--margin_y_5_rem--be41e";
export var mid_section = "post-template-module--mid_section--367aa";
export var podcast_icon = "post-template-module--podcast_icon--4cd91";
export var post_body = "post-template-module--post_body--30020";
export var post_profile_pic = "post-template-module--post_profile_pic--7bc35";
export var quotation_container = "post-template-module--quotation_container--e7275";
export var quotation_img = "post-template-module--quotation_img--3d1a8";
export var quotation_img_2 = "post-template-module--quotation_img_2--3ecca";
export var quotation_inner = "post-template-module--quotation_inner--a52a8";
export var row = "post-template-module--row--8a816";
export var single_post = "post-template-module--single_post--2e0e2";
export var video_icon = "post-template-module--video_icon--48ed9";